import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { TextField, Button, Box, Paper, createTheme, ThemeProvider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from 'assets/img/logo-s2-white2x.png';
import { dispatch as CustomDispatch } from 'redux/actions/index';
import { selectIsAuth, selectIsRegistered, selectTwoFARequired } from 'redux/selectors/index';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#FFBF00',
    },
    background: {
      paper: '#181818',
    },
    text: {
      primary: '#ffffff',
      secondary: '#9e9e9e',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: 6,
        fontWeight: 500,
      },
      containedPrimary: {
        boxShadow: 'none',
        backgroundColor: '#FFBF00',
        color: '#000000',
        '&:hover': {
          backgroundColor: '#FFD54F',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 6,
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#FFBF00',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#FFBF00',
        },
      },
      input: {
        color: '#ffffff',
        '&::placeholder': {
          color: '#9e9e9e',
          opacity: 1,
        },
      },
      notchedOutline: {
        borderColor: 'rgba(255, 255, 255, 0.2)',
      },
    },
    MuiInputLabel: {
      outlined: {
        color: '#9e9e9e',
        '&.Mui-focused': {
          color: '#FFBF00',
        },
      },
    },
    MuiPaper: {
      elevation3: {
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.5)',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    background: '#000',
  },
  loginCard: {
    width: '100%',
    maxWidth: 360,
    borderRadius: 16,
    overflow: 'hidden',
    padding: theme.spacing(4, 3),
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(24, 24, 24, 0.98)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
  },
  logo: {
    height: 40,
    marginBottom: theme.spacing(5),
  },
  form: {
    width: '100%',
  },
  textField: {
    marginBottom: theme.spacing(3),
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  loginButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    height: 48,
    fontSize: 16,
    fontWeight: 500,
  },
}));

function RegisterView({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);
  const isRegistered = useSelector(selectIsRegistered);
  const isTwoFA = useSelector(selectTwoFARequired);

  const [password, setPassword] = useState('');
  const [twoFA, setTwoFA] = useState('');

  const handleRegister = () => {
    if (!password || password.trim() === '') {
      console.warn('Empty password provided. Register attempt aborted.');
      return;
    }
    dispatch(CustomDispatch('register', password, twoFA))
      .then(() => {
        const search = history.location.search;
        const currentHost = window.location.href.split('register')[0];
        setTimeout(() => {
          window.location.replace(currentHost + 'setup' + search);
        }, 1200);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (isAuth || isRegistered) {
    return <Redirect to="/" />;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <Paper elevation={3} className={classes.loginCard}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src={logo} alt="Logo" className={classes.logo} />
            <p style={{ color: '#9e9e9e', marginBottom: '2rem' }}>
              Set a password for this bot instance
            </p>
            <form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleRegister();
              }}
              className={classes.form}
            >
              <TextField
                placeholder="Password"
                variant="outlined"
                fullWidth
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={classes.textField}
                InputProps={{ notched: false }}
              />
              {isTwoFA && (
                <TextField
                  placeholder="2FA Code"
                  variant="outlined"
                  fullWidth
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={twoFA}
                  onChange={(e) => setTwoFA(e.target.value)}
                  className={classes.textField}
                  InputProps={{ notched: false }}
                />
              )}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.loginButton}
                onClick={handleRegister}
                disableElevation
                type="submit"
              >
                Save password
              </Button>
            </form>
          </Box>
        </Paper>
      </div>
    </ThemeProvider>
  );
}

export default RegisterView;
