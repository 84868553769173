import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Box, Paper, Link } from '@material-ui/core';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import logo from 'assets/img/logo-s2-white2x.png';
import _ from 'lodash';

import { dispatch as CustomDispatch } from 'redux/actions/index';
import { selectTwoFARequired, selectDemoStatus, selectIsRequiredMetaMask } from 'redux/selectors/index';

import Classes from './BackgroundVideo.module.css';
import { SocketContext } from '../../SocketIO';
import { sendTokenViaSocket } from 'assets/js/common';
import ConnectWalletButton from 'components/MetaMask/ConnectWalletButton';
import { Web3ManageContext } from 'providers/Web3ManageProvider';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#FFBF00', // Gunthy yellow
    },
    background: {
      paper: '#181818',
    },
    text: {
      primary: '#ffffff',
      secondary: '#9e9e9e',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: 6,
        fontWeight: 500,
      },
      containedPrimary: {
        boxShadow: 'none',
        backgroundColor: '#FFBF00',
        color: '#000000',
        '&:hover': {
          backgroundColor: '#FFD54F',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 6,
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#FFBF00',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#FFBF00',
        },
      },
      input: {
        color: '#ffffff',
        '&::placeholder': {
          color: '#9e9e9e',
          opacity: 1,
        },
      },
      notchedOutline: {
        borderColor: 'rgba(255, 255, 255, 0.2)',
      },
    },
    MuiInputLabel: {
      outlined: {
        color: '#9e9e9e',
        '&.Mui-focused': {
          color: '#FFBF00',
        },
      },
    },
    MuiPaper: {
      elevation3: {
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.5)',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    background: '#000',
  },
  loginCard: {
    width: '100%',
    maxWidth: 360,
    borderRadius: 16,
    overflow: 'hidden',
    padding: theme.spacing(4, 3),
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(24, 24, 24, 0.98)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
  },
  logo: {
    height: 40,
    marginBottom: theme.spacing(5),
  },
  form: {
    width: '100%',
  },
  textField: {
    marginBottom: theme.spacing(3),
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  loginButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    height: 48,
    fontSize: 16,
    fontWeight: 500,
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  primaryLink: {
    color: '#FFBF00',
    fontSize: 15,
    textDecoration: 'none',
    marginBottom: theme.spacing(2),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  secondaryLinks: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(3),
  },
  secondaryLink: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 14,
    textDecoration: 'none',
    '&:hover': {
      color: '#ffffff',
      textDecoration: 'none',
    },
  },
  error: {
    color: '#FF5252',
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    padding: theme.spacing(1),
    borderRadius: 4,
    backgroundColor: 'rgba(255, 82, 82, 0.1)',
  },
}));

function LoginView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const { account } = useContext(Web3ManageContext);
  const reduxError = useSelector((state) => state.settings.error);
  const isTwoFA = useSelector(selectTwoFARequired);
  const isRequiredMetaMask = useSelector(selectIsRequiredMetaMask);
  const isDemo = useSelector(selectDemoStatus);

  const [state, setState] = useState({
    password: '',
    twoFA: '',
  });
  const [localError, setLocalError] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  // When a login is attempted, store the password used in that attempt.
  const [attemptedPassword, setAttemptedPassword] = useState('');
  // This flag lets us control whether incoming redux error should be processed.
  const [ignoreReduxError, setIgnoreReduxError] = useState(true);

  // Process redux error only if it belongs to the current login attempt
  useEffect(() => {
    if (
      !ignoreReduxError &&
      reduxError &&
      reduxError.message &&
      state.password === attemptedPassword
    ) {
      setLocalError(reduxError.message);
      if (state.password !== '') {
        setState((prev) => ({ ...prev, password: '' }));
      }
    }
  }, [reduxError, ignoreReduxError, state.password, attemptedPassword]);

  const handlePasswordChange = useCallback((event) => {
    event.persist();
    // As soon as the user types, clear any local error and ignore previous redux errors.
    setLocalError(null);
    setIgnoreReduxError(true);
    setState((prev) => ({ ...prev, password: event.target.value }));
  }, []);

  const handleTwoFAChange = useCallback((event) => {
    event.persist();
    setLocalError(null);
    setIgnoreReduxError(true);
    setState((prev) => ({ ...prev, twoFA: event.target.value }));
  }, []);

  // Function to perform the login attempt.
const attemptLogin = (password, twoFA, walletAddress) => {
  // Record the current password as the one used for this login attempt.
  setAttemptedPassword(password);
  // Turn on processing of redux errors for this attempt.
  setIgnoreReduxError(false);
  setIsLoggingIn(true)
  dispatch(CustomDispatch('login', password, twoFA, walletAddress))
    .then(() => {
      sendTokenViaSocket(socket);
      setIsLoggingIn(false)
    })
    .catch((error) => {
      console.log(error);
      setIsLoggingIn(false)
    });
};

// Create a debounced version of the login attempt.
const debouncedAttemptLogin = _.debounce(attemptLogin, 300);

const handleLogin = () => {
  const { password, twoFA } = state;
  // Prevent login attempts with an empty password.
  if (!password || password.trim() === '') {
    console.warn('Empty password provided. Login attempt aborted.');
    return;
  }
  let walletAddress = account;
  if (!isRequiredMetaMask) {
    walletAddress = undefined;
  }
  debouncedAttemptLogin(password, twoFA, walletAddress);
};

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleLogin();
      }
    },
    [handleLogin]
  );

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <Paper elevation={3} className={classes.loginCard}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src={logo} alt="Logo" className={classes.logo} />

            {localError && !isLoggingIn && <div className={classes.error}>{localError}</div>}

            <form
              className={classes.form}
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
            >
              <TextField
                placeholder={isDemo ? 'Password = demo' : 'Password'}
                variant="outlined"
                fullWidth
                type="password"
                value={state.password}
                onChange={handlePasswordChange}
                onKeyPress={handleKeyPress}
                className={classes.textField}
                InputProps={{
                  notched: false
                }}
              />

              {isTwoFA && (
                <TextField
                  placeholder="2FA Code"
                  variant="outlined"
                  fullWidth
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={state.twoFA}
                  onChange={handleTwoFAChange}
                  onKeyPress={handleKeyPress}
                  className={classes.textField}
                  InputProps={{
                    notched: false
                  }}
                />
              )}

              {isRequiredMetaMask && !isDemo && (
                <Box mb={3}>
                  <ConnectWalletButton width="100%" size="large" />
                </Box>
              )}

              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.loginButton}
                onClick={handleLogin}
                disableElevation
                type="submit"
              >
                Sign In
              </Button>

              <Box className={classes.linkContainer}>
                <Link
                  href="https://www.gunbot.com/leaderboard/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.primaryLink}
                >
                  Leaderboard
                </Link>

                <Box className={classes.secondaryLinks}>
                  <Link
                    href="https://wiki.gunthy.org/troubleshooting/reset-password"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.secondaryLink}
                  >
                    Forgot password?
                  </Link>

                  <Link
                    href="https://www.gunbot.com/support/help/email/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.secondaryLink}
                  >
                    Need help?
                  </Link>
                </Box>
              </Box>
            </form>
          </Box>
        </Paper>
      </div>
    </ThemeProvider>
  );
}

export default LoginView;
